.spinner-container{
  position: fixed;
  z-index: 99999998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cssload-container{
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  min-width: 50%;
  z-index: 99999999;
  width: 17.25%;
  height: 25%;
  background: #fff;
  transform: translate3d(-50%,-50%,0);
  -webkit-box-shadow: 1px 5px 5px 1px #ccc;
  -moz-box-shadow: 1px 5px 5px 1px #ccc;
  box-shadow: 1px 5px 5px 1px #ccc;

}
.cssload-messages{
  position: absolute;
  font-size: 14px;
  bottom: 0;
  margin: 0;
  margin-bottom: 15px;
  left: 50%;
  list-style: none;
  text-align: center;
  padding: 0;
  transform: translatex(-50%);
}
.cssload-messages li {
  position: relative;
  display: block;
}

.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgb(204,204,204);
  border-left-color: rgb(0,0,0);
  border-radius: 974px;
  -o-border-radius: 974px;
  -ms-border-radius: 974px;
  -webkit-border-radius: 974px;
  -moz-border-radius: 974px;
}

.cssload-whirlpool {
  margin: -24px 0 0 -24px;
  height: 49px;
  width: 49px;
  animation: cssload-rotate 1150ms linear infinite;
  -o-animation: cssload-rotate 1150ms linear infinite;
  -ms-animation: cssload-rotate 1150ms linear infinite;
  -webkit-animation: cssload-rotate 1150ms linear infinite;
  -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::before {
  content: "";
  margin: -22px 0 0 -22px;
  height: 43px;
  width: 43px;
  animation: cssload-rotate 1150ms linear infinite;
  -o-animation: cssload-rotate 1150ms linear infinite;
  -ms-animation: cssload-rotate 1150ms linear infinite;
  -webkit-animation: cssload-rotate 1150ms linear infinite;
  -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::after {
  content: "";
  margin: -28px 0 0 -28px;
  height: 55px;
  width: 55px;
  animation: cssload-rotate 2300ms linear infinite;
  -o-animation: cssload-rotate 2300ms linear infinite;
  -ms-animation: cssload-rotate 2300ms linear infinite;
  -webkit-animation: cssload-rotate 2300ms linear infinite;
  -moz-animation: cssload-rotate 2300ms linear infinite;
}



@keyframes cssload-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-rotate {
  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-rotate {
  100% {
    -ms-transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@media screen and (min-width:768px){
  .cssload-container{
    min-width: 33%;
  }
}
@media screen and (min-width:992px){
  .cssload-container{
    min-width: 25%;
  }
}
@media screen and (min-width:1400px){
  .cssload-container{
    min-width: 16.33%;
  }
}
